<template>
  <div class="col-12 col-lg-6 c g">
    <br><br>
    <div class="card card-body">
        <div v-if="step == 0">
        </div>
        <div v-if="step == 1">
            <h3>
                {{ title }}
            </h3>
            <div class="alert alert-warning g">
                <span class="text-dark">{{ description }}</span>
            </div>
            <hr>
            <div class="col-12 g" v-for="input in arr" :key="input.code">
                <div class="form-group" v-if="input.type == 'text'">
                  <h5 for="">{{ input.title }}</h5>
                  <input type="text"
                    class="form-control" v-model="data[input.code]" placeholder="اكتب هنا...">
                  <small id="helpId" class="form-text text-danger" v-if="input.required">* حقل الزامي</small>
                </div>
                <div class="form-group" v-if="input.type == 'textarea'">
                  <h5 for="">{{ input.title }}</h5>
                  <textarea class="form-control" v-model="data[input.code]" rows="3" placeholder="اكتب هنا..."></textarea>
                  <small id="helpId" class="form-text text-danger" v-if="input.required">* حقل الزامي</small>
                </div>
                <div v-if="input.type == 'checkbox' || input.type == 'radio'">
                    <div class="g">
                        <h5>{{ input.title }}</h5>
                    </div>
                    <div style="padding: 5px" v-for="option in input.options" :key="option">
                        <b-form-checkbox
                            v-model="data[input.code]"
                            :value="option"
                        >
                            {{ option }}
                        </b-form-checkbox>
                    </div>
                    <small id="helpId" class="form-text text-danger" v-if="input.required">* حقل الزامي</small>
                </div>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="done()">
                    <i class="fa fa-check"></i>
                    انهاء الاستبيان
                </button>
            </div>
        </div>
    </div>
    <div class="col-12 text-center text-muted">
        الخدمة مقدمة من نظام <a href="https://tahdir.net" target="_blank">التحضير الذكي</a>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, BFormRadio, BFormGroup } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BFormRadio,
        BFormGroup
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            step: 0,
            hideme: false,
            id: window.location.href.split("que2/")[1],
            arr: [],
            title: "",
            description: "",
            data: {},
            type: window.location.href.includes("gque2") ? '2' : '1'
        }
    },
    created(){
        var g = this;
        $.post(api + '/general/que2', {
            id: this.id,
            type: g.type
        }).then(function(r){
            g.step = 1
            if(r.status != 100){
                alert(r.response, 200)
                g.step = 0
            }else{
                g.arr = r.response.arr;
                g.title = r.response.title;
                g.description = r.response.description;
                g.arr.forEach(function(a){
                    if(a.type == 'checkbox'){
                        g.data[a.code] = []
                    } 
                })
            }
        }).fail(function(){
            alert("حدث خطأ في الاتصال", 200);
            g.step = 0
        })
    },
    methods: {
        done(){
            var g = this;
            var required = false;
            g.arr.forEach(function(a){
                if(a.required && (a.type == 'text' || a.type == 'textarea')){
                    if(g.data[a.code] == null || g.data[a.code] == ''){
                        if(!required){
                            required = a.title
                        }
                    }
                }
                if(a.required && (a.type == 'radio' || a.type == 'checkbox')){
                    if(!g?.data[a?.code] || g?.data[a?.code]?.length == 0 || g.data[a.code] == null || g.data[a.code] == ''){
                        if(!required){
                            required = a.title
                        }
                    }
                }
            })
            if(required){
                return alert(`الحقل '${required}' مطلوب`, 200)
            }
            $.post(api + '/general/que2-end', {
                id: this.id,
                data: JSON.stringify(this.data),
                type: g.type
            }).then(function(r){
                g.step = 1
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم الارسال بنجاح", 100)
                    g.step = 0
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200);
            })
        }
    }
}
</script>

<style>

</style>